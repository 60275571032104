<template>
  <div class="col-md-12" v-if="Object.keys(requisition).length !== 0">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6">
            <b>Name: </b> {{ requisition.hub.name }}<br>
            <b>Phone: </b> {{ requisition.hub.phone }}<br>
            <b>Address: </b> {{ requisition.hub.address }}<br>
            <b>Email: </b> {{ requisition.hub.email }}<br>
          </div>
          <div class="col-md-6">
            <b>Hub Employees: </b> {{ requisition.hub.hub_employees }}<br>
            <b>Department Working: </b>
            <template v-for="(department, index) in requisition.hub.departments">
                <span class="item" :key="index">
                    {{ department.department_name }}
                      <span v-if="index != Object.keys(requisition.hub.departments).length - 1">, </span>
                </span>
            </template>
            <br>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'requisition_hub_detail',
  props: ['requisition'],
  data() {
    return {}
  },
  methods: {},
}
</script>

<style scoped>
.item:last-child .comma {
  display: none;
}
</style>
