<template>
  <div class="col-md-12">
    <div class="card">
      <a-spin :spinning="spinning" :delay="delayTime">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-6">
              <b>Requisition ID: </b> {{ requisition.requisition_number }}<br>
              <b>Requisition Status: </b>
              <a-tag color="#f50" v-if="requisition.requisition_status.includes('STORE_IN_CHARGE_PENDING') && !requisition.requisition_status.includes('STORE_IN_CHARGE_APPROVED')">Pending</a-tag>
              <a-tag color="#87d068" v-if="requisition.requisition_status.includes('ADMIN_PENDING') && requisition.requisition_status.includes('ADMIN_APPROVED')">Approved</a-tag><br>
              <b>Requisition Created at : </b> {{ customDate(requisition.created_at) }}<br>
            </div>
            <div class="col-sm-6">
              <b>Hub: </b> {{ requisition.hub.name }}<br>
              <b>Requisition Approved at: </b> <span v-if="requisition.requisition_statuses.filter(e => e.status === 'ADMIN_APPROVED').length > 0">{{ customDate(requisition.requisition_statuses.find(e => e.status === 'ADMIN_APPROVED').created_at) }}</span>
            </div>
          </div>
        </div>
      </a-spin>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'status',
  props: ['requisition', 'spinning', 'delayTime'],
  data() {
    return {}
  },
  methods: {
    customDate(date) {
      return moment(date).format('LL')
    },
  },
}
</script>

<style scoped>

</style>
